import "bootstrap";

const inputPassword = document.getElementById("password");
const btnTogglePassword = document.getElementById("btn-toggle-password");
const iconTogglePassword = btnTogglePassword.querySelector("i");

btnTogglePassword.addEventListener("click", () => {
    const isPasswordVisible = inputPassword.getAttribute("type") === "text";
    inputPassword.setAttribute("type", isPasswordVisible ? "password" : "text");
    iconTogglePassword.classList.toggle("fa-eye", isPasswordVisible);
    iconTogglePassword.classList.toggle("fa-eye-slash", !isPasswordVisible);
});
